.wrapper {
    @apply block rounded-t-2xl p-4 fixed bottom-0 w-full bg-white md:hidden z-50;
    @apply transition-transform;
    transform: translateY(100%);
    [data-sticky-button-show="true"] &, [data-sticky-button-show="true"][data-footer-entered="false"] &{
        transform: translateY(0);
    }
    [data-sticky-button-show="true"][data-footer-entered="true"] & {
        transform: translateY(100%);
    }
}